import React,{useEffect, useState} from 'react'
import ListPDF from "../Componets/ListPDF";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    isAndroid,
    isIOS,
} from "react-device-detect";

function Aux(){

   return(
       <div className="main-panel">
           <div className="content">
               <div className="page-inner">
                   <div className="row">
                       <div className="col-md-10">
                           <div className="card">
                               <div className="card-header">
                                   <img src="/assets/img/imagen_web.png" alt="" />
                               </div>
                               <div className="card-body">
                                    <ListPDF/>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
   )
}

export default  Aux;
