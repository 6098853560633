import React, {useState, useEffect} from 'react'
import {Loader, Types} from 'react-loaders';
import BlockUi from "react-block-ui";
import 'react-block-ui/style.css';
import 'react-activity/dist/react-activity.css';
import DotsLoading from "./App/DotsLoading";
import Aux from "./App/Aux";


function App() {

    const [blocking, setBlocking] = useState(false);
    const [loaderType] = useState('line-spin-fade-loader');


    return (
        blocking === false ?
            <Aux/>
            :
            <DotsLoading/>
    )


}

export default App;
