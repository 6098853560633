import React,{useState} from 'react';
import PDFViewer from 'pdf-viewer-reactjs'
import DotsLoading from "../App/DotsLoading";



function ListPDF(){

    const[url]=useState('http://www.lista.miami.uy/listatotal.pdf');


    return(
        <PDFViewer
            document={{
                url: "https://cors-anywhere.herokuapp.com/" + url,
            }}
            loader={
                <DotsLoading/>
            }
            scale={1.3}
            scaleStep={0.5}
            maxScale={5}
            minScale={0.5}

        />
    )


}

export  default  ListPDF;
