import  React from 'react'
import Dots from 'react-activity/lib/Spinner';
import 'react-activity/dist/react-activity.css';

const DotsLoading = (props) =>{

    const marginLeftDot={
        marginLeft:'45%',
        marginTop:'10%',
    }

    return(
        <div className="col-sm-12 col-md-12 col-xs-12 col-lg-12">
            <div style={marginLeftDot}>
                <Dots
                    size={70}
                    color={'#30beac'}/>
            </div>
        </div>
    )
}

export  default  DotsLoading;
